import React from 'react'
import { getImage, GatsbyImage } from "gatsby-plugin-image";

function Portfolio({ className, portfolios }) {

  if (!portfolios) {
    return <></>
  }

  return (
    <>
      <section
        className={`appie-services-2-area pb-100 ${className}`}
        id="service"
      >
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-6 col-md-8">
              <div className="appie-section-title">
                <h3 className="appie-title">Välj det som passar Dig</h3>
                <p>Vi har tagit fram välbalanserade portföljer .....</p>
              </div>
            </div>
            <div className="col-lg-6 col-md-4">
              <div className="appie-section-title text-right">
                <a className="main-btn" href="#">
                  Se alla våra portföljer <i className="fal fa-arrow-right" />
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            {portfolios.map((port, i) => 
              <div key={i} className="col-lg-4 col-md-6">
                <div
                  className="appie-single-service-2 mt-30 wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="200ms"
                >
                  <div className="portfolio-icon-wrapper">
                    <img width="80px" src={port.thumbnail.publicURL} />
                  </div>
                  <h4 className="title portfolio-title">{port.title}</h4>
                  <p className="portfolio-under-text">{port.description}</p>
                  {port.percentages && port.percentages.map((p, j) => 
                    <p key={j}>
                      <span className="portfolio-precentage">{p.percentage}%</span>
                      {p.name}
                    </p>
                  )}
                  <a href="#">
                    Läs mer <i className="fal fa-arrow-right" />
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

export default Portfolio
